<template>
  <div class="line-view container">
    <div v-if="webId != null">

      <div v-if="lineUserId !=null">
        <LineList />
      </div>
      <div v-else>
        <line-login-button  @result="result"/>
      </div>

    </div>
    <div v-else class="text-center">
      <br />
      <SolidLoginButton />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Line",
  components: {
    'SolidLoginButton': () => import('@/components/solid/SolidLoginButton'),
    'LineList': () => import('@/components/line/LineList'),
    'LineLoginButton': () => import('@/components/LineLoginButton'),
  },
  computed:{
    webId(){
      return this.$store.state.solid.webId
    },
    lineUserId(){
      return this.$store.state.line.lineUserId
    }
  },
  data() {
    return {
      //lineUserId: null
      // clientId: '',
      // clientSecret: '',
      // callbackUri: ''
    }
  },
  created: function () {
    // line login check
    if (localStorage.getItem("access_token") != null) {
      const token = localStorage.getItem("access_token")
      this.$store.dispatch('line/getLineUser', token);
    }
  },
  methods: {
    result(res) {
      console.log("res:"+res)
      this.$router.go();
    },
  }
}
</script>
<style>
.line-view {
  text-align: left;
  padding: 0;
}
</style>
